import React from 'react';
import DashboardLayout from "../../../../eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../eco/Navbars/DashboardNavbar";
import SoftBox from "../../../../components/SoftBox";
import {useSelector} from "react-redux";
import Details from "./form-steps/Details";
import Addresses from "./form-steps/Addresses";
import Currency from "./form-steps/Currency";
import ConsumableService from "./form-steps/ConsumableService";
import SoftTypography from "../../../../components/SoftTypography";
import StepHeader from "./step-header/StepHeader";

function CreateParentOrganization() {
    const stepForm = useSelector((state)=> state.organization.stepForm)
    const renderForm = () => {
        switch (stepForm) {
            case 0:
                return <Details/>
            case 1:
                return <Addresses/>
            case 2:
                return <Currency/>
            case 3:
                return <ConsumableService/>
            case 4:
                return <ConsumableService/>
        }
    }
    console.log(stepForm);
    return (
       <DashboardLayout>
           <DashboardNavbar/>
           <SoftTypography>
               Create Parent Organization
           </SoftTypography>
           <SoftBox mt={2} mb={2}>
               <StepHeader numberOfStep={4} step={stepForm}/>
           </SoftBox>
           <SoftBox>
               {renderForm()}
           </SoftBox>
       </DashboardLayout>
    );
}

export default CreateParentOrganization;