import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useSortBy,
} from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";
import DataTableHeadCell from "eco/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "eco/Tables/DataTable/DataTableBodyCell";

function DataTable({ entriesPerPage, canSearch, showTotalEntries, table, pagination, isSorted, noEndBorder, theData,}) {
    const defaultValue = entriesPerPage.defaultValue || 15;
    const entries = entriesPerPage.entries || [15, 25, 35, 45, 55, 100];

    // Memoizing columns and data
    const columns = useMemo(() => table.columns, [table]);
    const data = useMemo(() => theData || [], [theData]);

    // Initializing react-table hooks
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Using page for pagination
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: defaultValue },
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    // Search state
    const [search, setSearch] = useState(globalFilter);
    const onSearchChange = (value) => {
        setGlobalFilter(value || undefined);
    };

    // Handling page size change
    const setEntriesPerPage = ({ value }) => {
        setPageSize(value);
    };

    // Pagination UI
    const renderPagination = pageOptions.map((option, idx) => (
        <SoftPagination
            item
            key={idx}
            value={String(option + 1)}
            onClick={() => gotoPage(idx)}
            active={pageIndex === idx}
        >
            {option + 1}
        </SoftPagination>
    ));

    // Input for changing pages
    const handleInputPaginationValue = (event) =>
        gotoPage(Number(event.target.value) - 1);

    // Render the table component
    return (
        <TableContainer sx={{ boxShadow: "none" }}>
            {(entriesPerPage || canSearch) && (
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    {entriesPerPage && (
                        <SoftBox display="flex" alignItems="center">
                            <SoftSelect
                                defaultValue={{
                                    value: defaultValue,
                                    label: defaultValue,
                                }}
                                options={entries.map((entry) => ({
                                    value: entry,
                                    label: entry,
                                }))}
                                onChange={setEntriesPerPage}
                                size="small"
                            />
                            <SoftTypography variant="caption" color="secondary">
                                &nbsp;&nbsp;entries per page
                            </SoftTypography>
                        </SoftBox>
                    )}
                    {canSearch && (
                        <SoftBox width="12rem" ml="auto">
                            <SoftInput
                                placeholder="Search..."
                                value={search}
                                onChange={({ currentTarget }) => {
                                    setSearch(currentTarget.value);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </SoftBox>
                    )}
                </SoftBox>
            )}
            <Table {...getTableProps()}>
                <SoftBox component="thead">
                    {headerGroups.map((headerGroup, key) => (
                        <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <DataTableHeadCell key={column.id}>
                                    {column.render("Header")}
                                </DataTableHeadCell>
                            ))}
                        </TableRow>
                    ))}
                </SoftBox>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, key) => {
                        prepareRow(row);
                        return (
                            <TableRow key={key} {...row.getRowProps()}>
                                {row.cells.map((cell, key) => (
                                    <DataTableBodyCell
                                        key={key}
                                        noBorder={noEndBorder && page.length - 1 === key}
                                        align={cell.column.align || "left"}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </DataTableBodyCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <SoftBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            >
                {showTotalEntries && (
                    <SoftBox mb={{ xs: 3, sm: 0 }}>
                        <SoftTypography variant="button" color="secondary" fontWeight="regular">
                            Showing {pageIndex * pageSize + 1} to{" "}
                            {Math.min((pageIndex + 1) * pageSize, data.length)} of {data.length} entries
                        </SoftTypography>
                    </SoftBox>
                )}
                {pageOptions.length > 1 && (
                    <SoftPagination
                        variant={pagination.variant || "gradient"}
                        color={pagination.color || "info"}
                    >
                        {canPreviousPage && (
                            <SoftPagination item onClick={previousPage}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                            </SoftPagination>
                        )}
                        {renderPagination.length > 7 ? (
                            <SoftBox width="5rem" mx={1}>
                                <SoftInput
                                    inputProps={{
                                        type: "number",
                                        min: 1,
                                        max: pageOptions.length,
                                    }}
                                    value={pageIndex + 1}
                                    onChange={handleInputPaginationValue}
                                />
                            </SoftBox>
                        ) : (
                            renderPagination
                        )}
                        {canNextPage && (
                            <SoftPagination item onClick={nextPage}>
                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                            </SoftPagination>
                        )}
                    </SoftPagination>
                )}
            </SoftBox>
        </TableContainer>
    );
}

DataTable.defaultProps = {
    entriesPerPage: { defaultValue: 15, entries: [15, 25, 35, 45, 55, 100] },
    canSearch: false,
    showTotalEntries: true,
    pagination: { variant: "gradient", color: "info" },
    isSorted: true,
    noEndBorder: false,
};

DataTable.propTypes = {
    entriesPerPage: PropTypes.oneOfType([
        PropTypes.shape({
            defaultValue: PropTypes.number,
            entries: PropTypes.arrayOf(PropTypes.number),
        }),
        PropTypes.bool,
    ]),
    canSearch: PropTypes.bool,
    showTotalEntries: PropTypes.bool,
    table: PropTypes.objectOf(PropTypes.array).isRequired,
    pagination: PropTypes.shape({
        variant: PropTypes.oneOf(["contained", "gradient"]),
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
    }),
    isSorted: PropTypes.bool,
    noEndBorder: PropTypes.bool,
};

export default DataTable;
