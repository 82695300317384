import styled, {createGlobalStyle} from "styled-components";


export const Heading = styled.h1`
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
`

export const GlobalStyled = createGlobalStyle`
    :root {
        --primary-color: #2E572C ;
        --secondary-color:   #e6e8eb;
    }
`