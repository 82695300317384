import formValues from './form';
import * as Yup from "yup";

const {
    formField: {
        first_name,
        last_name,
        email_address,
        phone,
        photo,
        parent_organization,
    }
} = formValues

const superAdminFormValidation = Yup.object().shape({
    [first_name.name]: Yup.string().required(first_name.errorMsg),
    [last_name.name]: Yup.string().required(last_name.errorMsg),
    [phone.name]: Yup.string()
        .required(phone.errorMsg)
        .matches(
            /^250\d{9}$/,
            "Phone number must be 12 digits and start with '250'"
        ),
    [email_address.name]: Yup.string().required(email_address.errorMsg),
    [photo.name]: Yup.string().required(photo.errorMsg),
    [parent_organization.name]: Yup.string().required(parent_organization.errorMsg),
})

export default superAdminFormValidation;