import formValues from './form';

const {
    formField: {
        first_name,
        last_name,
        email_address,
        phone,
        photo,
        parent_organization,
    }
} = formValues

const superAdminFormInitialValues = {
    [first_name.name]: "",
    [last_name.name]: "",
    [phone.name]: "",
    [email_address.name]: "",
    [photo.name]: "",
    [parent_organization.name]: "",
}

export default superAdminFormInitialValues;
