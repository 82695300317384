/** @format */

import React from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid";
import SoftButton from "../../../../../components/SoftButton";
import { useDispatch } from "react-redux";
import { setStepForm } from "../../../../../features/slices/organizationSlice";
// import {CircularProgress} from "@mui/material";

const ConsumableService = () => {
    const dispatch = useDispatch();
    const { handleSubmit } = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            console.log("uuuuu");
            dispatch(setStepForm(4));
        },
    });
    return (
        <>
            <Container>
                <Heading>Consumable Services</Heading>
                <FormContainer onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Server Storage</MainLabel>
                            <FormGroup>
                                <ServiceContainer>
                                    <Label>Freemium Storage</Label>
                                    <TarifContainer>
                                        <TextStyled>5 Gb</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                                <ServiceContainer>
                                    <Label>Price per Gb</Label>
                                    <TarifContainer>
                                        <TextStyled>RWF 5,000</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>USSD Code Sessions</MainLabel>
                            <FormGroup>
                                <ServiceContainer>
                                    <Label>Freemium Sessions</Label>
                                    <TarifContainer>
                                        <TextStyled>2000</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                                <ServiceContainer>
                                    <Label>Price Per Sessionb</Label>
                                    <TarifContainer>
                                        <TextStyled>RWF 15</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Bulk SMS</MainLabel>
                            <FormGroup>
                                <ServiceContainer>
                                    <Label>Freemium Bulk SMS</Label>
                                    <TarifContainer>
                                        <TextStyled>2000</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                                <ServiceContainer>
                                    <Label>Price SMS</Label>
                                    <TarifContainer>
                                        <TextStyled>RWF 15</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Tree Update Cost</MainLabel>
                            <FormGroup>
                                <ServiceContainer>
                                    <Label>Freemium Services</Label>
                                    <TarifContainer>
                                        <TextStyled>0</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                                <ServiceContainer>
                                    <Label>Price Per record</Label>
                                    <TarifContainer>
                                        <TextStyled>RWF 4</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Weather Plan</MainLabel>
                            <FormGroup>
                                <ServiceContainer>
                                    <Label>Freemium Services</Label>
                                    <TarifContainer>
                                        <TextStyled>3 Days</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                                <ServiceContainer>
                                    <Label>14 days Forecast</Label>
                                    <TarifContainer>
                                        <TextStyled>Rwf 35,000</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MainLabel>Montly Fees</MainLabel>
                            <FormGroup>
                                <ServiceContainer>
                                    <Label>28 days</Label>
                                    <TarifContainer>
                                        <TextStyled>Rwf 300,000</TextStyled>
                                    </TarifContainer>
                                </ServiceContainer>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <SubmitContainer>
                        <SoftButton
                            type='submit'
                            variant='gradient'
                            color='dark'
                        >
                            save
                        </SoftButton>
                    </SubmitContainer>
                </FormContainer>
            </Container>
        </>
    );
};

export default ConsumableService;

const Container = styled.div`
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    padding: 1em 2em;
    -webkit-box-shadow: -1px 3px 5px 0px rgba(181, 181, 181, 1);
    -moz-box-shadow: -1px 3px 5px 0px rgba(181, 181, 181, 1);
    box-shadow: -1px 3px 5px 0px rgba(181, 181, 181, 1);
    background-color: #fff;
    border-radius: 10px;
`;

const Heading = styled.h2`
    font-size: 20px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: semibold;
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
`;

const ServiceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.425rem;
`;

const Label = styled.label`
    font-size: 16px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 600;
`;

const MainLabel = styled.label`
    font-size: 18px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 600;
`;

const TarifContainer = styled.div`
    padding: 6px 14px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
`;

const TextStyled = styled.span`
    font-size: 15px;
    font-weight: 600;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: end;
`;
