const superAdminform = {
    formId: "new-super-admin",
    formField: {
        first_name: {
            name: "first_name",
            label: "First Name",
            type: "text",
            placeholder: "e.g. John",
            errorMsg: "First name is required.",
        },
        last_name: {
            name: "last_name",
            label: "Last Name",
            type: "text",
            placeholder: "e.g. Doe",
            errorMsg: "Last name is required.",
        },
        phone: {
            name: "phone",
            label: "Phone",
            type: "string",
            placeholder: "eg. 0788xxxxxx",
            errorMsg: "Phone is required.",
            invalidMsg: "Your phone is invalid",
        },
        email_address: {
            name: "email_address",
            label: "Email Address",
            type: "email",
            placeholder: "e.g. joe@gmail.com",
            errorMsg: "Email is required.",
        },
        photo: {
            name: "photo",
            label: "Avatar image",
            type: "file",
            placeholder: "e.g. joe@gmail.com",
            errorMsg: "Avator image is required.",
        },
        parent_organization: {
            name: "parent_organization",
            label: "Parent Organization",
            type: "select",
            options: [],
            errorMsg: "Parent organisation is required.",
        },
    },
};

export default superAdminform;