import { createSlice } from '@reduxjs/toolkit'


const packageSlice = createSlice({
    name: 'package',
    initialState: {
        package: {
            name: null,
            packages: []
        }
    },
    reducers: {
        setName: (state, action) => {
            state.package.name = action.payload;
        },
        setPackage: (state, action) => {
            state.package.packages.push(action.payload);
        }
    }
})

export const {setName, setPackage} = packageSlice.actions;

export default packageSlice;