/** @format */

import {
    parentOrgFormStep1,
    parentOrganizationFormStep2,
    parentOrganizationFormStep3,
} from "./form";
import * as Yup from "yup";

const {
    formField: {
        name,
        person_contact,
        contact_email,
        website,
        contact_phone,
        package_name,
    },
} = parentOrgFormStep1;

const {
    formField: { country_id, district, province, village, cell, sector },
} = parentOrganizationFormStep2;

const {
    formField: { currency, currency_symbol },
} = parentOrganizationFormStep3;

export const parentOrganizationFormStep1Schema = Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [person_contact.name]: Yup.string().required(person_contact.errorMsg),
    [contact_email.name]: Yup.string().email().required(contact_email.errorMsg),
    [contact_phone.name]: Yup.string(),
    [package_name.name]: Yup.string(),
    [website.name]: Yup.string(),
    [package_name.name]: Yup.string().required(package_name.errorMsg),
});

export const parentOrganizationStep2Schema = Yup.object().shape({
    [cell.name]: Yup.string().required("This field is required"),
    [district.name]: Yup.string().required("This field is required"),
    [sector.name]: Yup.string().required("This field is required"),
    [village.name]: Yup.string().required("This field is required"),
    [province.name]: Yup.string().required("This field is required"),
    [country_id.name]: Yup.string().required(country_id.errorMsg),
});

export const parentOrganization3Sschema = Yup.object().shape({
    [currency.name]: Yup.string().required(currency.errorMsg),
    [currency_symbol.name]: Yup.string().required(currency.errorMsg),
});
