/** @format */

import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

const ChartContainer = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
`;

const LegendItem = styled.div`
    display: flex;
    align-items: center;
    margin-right: 20px;
`;

const LegendColor = styled.span`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 8px;
`;

const LegendText = styled.span`
    font-size: 14px;
    color: #333;
`;

const ButtonGroup = styled.div`
    display: flex;
    background-color: #f0f0f0;
    border-radius: 4px;
    overflow: hidden;
`;

const Button = styled.button`
    padding: 8px 12px;
    font-size: 12px;
    border: none;
    background-color: ${(props) => (props.active ? "white" : "transparent")};
    color: ${(props) => (props.active ? "#333" : "#666")};
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: ${(props) => (props.active ? "white" : "#e0e0e0")};
    }
`;

const SubscriptionChart = ({ data }) => {
    const [activeButton, setActiveButton] = useState("Year");

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const options = {
        chart: {
            type: "area",
            height: 350,
            toolbar: {
                show: false,
            },
        },
        colors: ["#80CAEE", "#3C50E0"],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        xaxis: {
            categories: months,
        },
        yaxis: {
            title: {
                text: "Number of Subscribers",
            },
        },
        tooltip: {
            x: {
                format: "MMM",
            },
        },
        grid: {
            show: true,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
            },
        },
    };

    const series = [
        {
            name: "Subscribers",
            data: data,
        },
        // {
        //     name: "Total Subscribers",
        //     data: data.totalSubscribers,
        // },
    ];

    return (
        <ChartContainer>
            <Header>
                <div style={{ display: "flex" }}>
                    <LegendItem>
                        <LegendColor color='#80CAEE' />
                        <LegendText>Subscribers</LegendText>
                    </LegendItem>
                    {/* <LegendItem>
                        <LegendColor color='#3C50E0' />
                        <LegendText>Total Subscribers</LegendText>
                    </LegendItem> */}
                </div>
                <ButtonGroup>
                    <Button
                        active={activeButton === "Month"}
                        onClick={() => setActiveButton("Month")}
                    >
                        Month
                    </Button>
                    <Button
                        active={activeButton === "Quarter"}
                        onClick={() => setActiveButton("Quarter")}
                    >
                        Quarter
                    </Button>
                    <Button
                        active={activeButton === "Year"}
                        onClick={() => setActiveButton("Year")}
                    >
                        Year
                    </Button>
                </ButtonGroup>
            </Header>
            <ReactApexChart
                options={options}
                series={series}
                type='area'
                height={350}
            />
        </ChartContainer>
    );
};

export default SubscriptionChart;
