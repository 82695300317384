import React from 'react';
import styled from "styled-components";

function CreateName({name, setNamePage}) {
    return (
        <FormInputArea>
            <FormGroup>
                <Label>Package Name</Label>
                <InputText type="text" value={name} onChange={(e)=> setNamePage(e.target.value)} />
            </FormGroup>
        </FormInputArea>
    );
}

const FormInputArea = styled.div`
    height: 40px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
`

const InputText = styled.input`
    padding: 10px 20px;
    border: #ccc 1px solid;
    border-radius: 5px;
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
`

const Label = styled.label`
    font-weight: bold;
    font-size: 1.2rem;
`
export default CreateName;