import DefaultCell from "components/dataTableComponents/DefaultCell";
import styled from "styled-components";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";

const overviewColumn = {
    columns: [
        {
            Header: "ID",
           // This is where the ID will be accessed
            Cell: ({ value }) => <DefaultCell value={`#${value ?? "N/A"}`} />,  // Display the ID with a hashtag prefix
        },
        {
            Header: "Parent Organization",
            accessor: "parentOrganization",  // Assuming data has a 'parent_organization' field
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,  // Display Parent Organization name
        },
        {
            Header: "Contact",
            accessor: "contact",  // Assuming data has a 'contact' field (email or phone)
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,  // Display the contact information
        },
        {
            Header: "Package",
            accessor: "package",  // Assuming data has a 'package' field
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,  // Display the package type
        },
        {
            Header: "Status",
            accessor: "status",  // Assuming data has a 'status' field
            Cell: ({ value }) => (
                <DefaultCell
                    value={value ?? "N/A"}
                    style={{
                        color: value === "Enable" ? "green" : value === "Disable" ? "red" : "black",
                    }}  // Conditionally style the status
                />
            ),
        },
        {
            Header: "Country",
            accessor: "country",  // Assuming data has a 'country' field
            Cell: ({ value }) => <DefaultCell value={value ?? "N/A"} />,  // Display the country
        },
        {
            Header: "Actions",
            accessor: "id",  // Reuse the ID to pass it into the action column
            Cell: ({ value }) => (
                <div style={{ display: "flex", gap: "1rem" }}>
                    <Tooltip arrow={false} title="View Details">
                        <NavLink
                            style={{ fontSize: "25px" }}
                            to={`#`}  // Use the ID in the link
                        >
                            <RemoveRedEyeIcon fontSize="inherit" />
                        </NavLink>
                    </Tooltip>
                </div>
            ),
        },
    ],
};

// Styled Components
export const NavLink = styled(Link)`
    text-decoration: none;
    color: black;
    &:hover {
        text-decoration: underline !important;
    }
`;

export const Logo = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50px;
`;

export default overviewColumn;
