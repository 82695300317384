import React from 'react';
import DashboardLayout from "../../../../../eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../../eco/Navbars/DashboardNavbar";
import SoftBox from "../../../../../components/SoftBox";
import Grid from "@mui/material/Grid";
import {Form, Formik} from "formik";
import superAdminFormInitialValues from "./schemas/initialValues";
import superAdminFormValidation from "./schemas/validation";
import Card from "@mui/material/Card";
import SoftButton from "../../../../../components/SoftButton";
import {CircularProgress} from "@mui/material";
import superAdminform from "./schemas/form";
// import ErrorMessageResponse from "../../../../../components/ErrorMessage/ErrorMessageResponse";
import Footer from "../../../../../eco/Footer";
import SuperAdminField from "./components/fields";


function CreateSuperAdmin() {
    const { formId, formField} = superAdminform
    const submitForm = async (values, actions) => {

        try {
        } catch (err) {
            actions.setSubmitting(false);
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftBox py={3} mb={20}>
                <Grid container justifyContent='center' sx={{ height: "100%" }}>
                    <Grid item xs={12} lg={8}>
                        <Formik
                            initialValues={superAdminFormInitialValues}
                            validationSchema={superAdminFormValidation}
                            onSubmit={submitForm}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  isSubmitting,
                                  setFieldValue,
                              }) => (
                                <Form id={formId} autoComplete='off'>
                                    <Card sx={{ height: "100%" }}>
                                        <SoftBox p={2}>
                                            <SoftBox>
                                                <SuperAdminField
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                        setFieldValue,
                                                    }}
                                                />

                                                <SoftBox
                                                    mt={2}
                                                    width='100%'
                                                    display='flex'
                                                    justifyContent='space-between'
                                                >
                                                    <SoftButton
                                                        disabled={isSubmitting}
                                                        type='submit'
                                                        variant='gradient'
                                                        color='dark'
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    color: "#ffffff",
                                                                }}
                                                            />
                                                        ) : (
                                                            `create`
                                                        )}
                                                    </SoftButton>
                                                </SoftBox>

                                                {/*{showError &&*/}
                                                {/*    !isLoading &&*/}
                                                {/*    isError && (*/}
                                                {/*        <ErrorMessageResponse>*/}
                                                {/*            {*/}
                                                {/*                error?.data*/}
                                                {/*                    ?.message*/}
                                                {/*            }*/}
                                                {/*        </ErrorMessageResponse>*/}
                                                {/*    )}*/}
                                            </SoftBox>
                                        </SoftBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CreateSuperAdmin;