/** @format */

import React, { useState } from "react";
import DashboardNavbar from "../../../eco/Navbars/DashboardNavbar";
import DashboardLayout from "../../../eco/LayoutContainers/DashboardLayout";
import SoftBox from "../../../components/SoftBox";
import { Button, Card, Grid } from "@mui/material";
import SoftTypography from "../../../components/SoftTypography";
import breakpoints from "../../../assets/theme/base/breakpoints";
import { FaPlus } from "react-icons/fa6";
import LoadingCardSkeleton from "../../../components/skeleton/LoadingSkeleton";
import OrgCard from "../../../eco/Cards/OrgCard";
import DataTable from "../../../eco/Tables/DataTable";
import overviewColumn from "./data-table/column.admin";
import { dummyDataPackage } from "../../../constants/data";
import { useNavigate } from "react-router-dom";

function OverviewPage() {
    const { values } = breakpoints;
    const [isLoadingStat] = useState(false);
    const nagivate = useNavigate();

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox my={3}>
                <SoftBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <SoftTypography
                        variant={window.innerWidth < values.sm ? "h3" : "h2"}
                        textTransform='capitalize'
                        fontWeight='bold'
                        gutterBottom
                    >
                        Administration statistics
                    </SoftTypography>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            color: "white",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={() => {
                                nagivate("/overview/create-super-user");
                            }}
                            startIcon={<FaPlus size={20} />}
                            variant='contained'
                            style={{
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                            }}
                            color='primary'
                        >
                            create super admin
                        </Button>
                        <Button
                            onClick={() => {
                                nagivate(
                                    "/overview/create-parent-organization"
                                );
                            }}
                            startIcon={<FaPlus size={20} />}
                            variant='contained'
                            style={{ color: "white" }}
                            color='primary'
                        >
                            create parent organisation
                        </Button>
                    </div>
                </SoftBox>
                <SoftBox my={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={10}
                                            suffix=''
                                            title='Super Admin'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={10}
                                            suffix=''
                                            title='Admin'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={10}
                                            suffix=''
                                            title='Total Organizations'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <LoadingCardSkeleton
                                        height={"150px"}
                                        isLoading={isLoadingStat}
                                    >
                                        <OrgCard
                                            count={10}
                                            suffix=''
                                            title='Total Parent'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox>
                    <LoadingCardSkeleton
                        isLoading={isLoadingStat}
                        height='333px'
                    >
                        <Card>
                            <DataTable
                                table={overviewColumn}
                                entriesPerPage={5}
                                canSearch
                                theData={dummyDataPackage}
                            />
                        </Card>
                    </LoadingCardSkeleton>
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
}

export default OverviewPage;
