import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Avatar,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'

const AdminProfileModal = ({ open, onClose, profileData = {} }) => {
    const {
        name = '',
        status = '',
        parentOrganization = '',
        organization = '',
        email = '',
        phone = '',
        photoUrl = '',
    } = profileData;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Admin Profile
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4">Name</Typography>
                        <Typography variant="body1">{name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Status</Typography>
                        <Typography variant="body1">{status}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Parent organization</Typography>
                        <Typography variant="body1">{parentOrganization}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Organization</Typography>
                        <Typography variant="body1">{organization}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Contact Info</Typography>
                        <Typography variant="body1">Email: {email}</Typography>
                        <Typography variant="body1">Phone: {phone}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Profile Photo</Typography>
                        <Avatar
                            sx={{ width: 100, height: 100, fontSize: '3rem' }}
                            src={photoUrl}
                        >
                            ?
                        </Avatar>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <BottonUpload>
                    Update Profile
                </BottonUpload>
                <Button style={{color: "white"}} variant="contained" onClick={onClose}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const BottonUpload = styled(Button)`
    color: darkgreen !important;
    background-color: #d0d2d6 !important;
    padding: 0.8rem 0.725rem !important;
    &:hover {
        outline: darkgreen !important;
    }
`

export default React.memo(AdminProfileModal);