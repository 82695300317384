import React from 'react';
import SoftBox from "../../../../../../../components/SoftBox";
import SoftTypography from "../../../../../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import FormField from "../../../../../farmers/addNewFarmer/component/formField";
import Select from "@mui/material/Select";
import SoftInput from "../../../../../../../components/SoftInput";
import MenuItem from "@mui/material/MenuItem";

const SuperAdminField = ({ formData }) => {
    const { formField, values, errors, touched, setFieldValue } = formData;

    return (
        <SoftBox>
            <SoftBox lineHeight={0}>
                <SoftTypography variant='h5' fontWeight='bold'>
                     Super Admin User
                </SoftTypography>
                <SoftTypography
                    variant='button'
                    fontWeight='regular'
                    color='text'
                >
                    Mandatory informations
                </SoftTypography>
            </SoftBox>
            <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.first_name.type}
                            label={formField.first_name.label}
                            name={formField.first_name.name}
                            value={values.first_name}
                            placeholder={formField.first_name.placeholder}
                            error={errors.first_name && touched.first_name}
                            success={
                                values?.first_name?.length > 0 &&
                                !errors?.first_name
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.last_name.type}
                            label={formField.last_name.label}
                            name={formField.last_name.name}
                            value={values.last_name}
                            placeholder={formField.last_name.placeholder}
                            error={errors.last_name && touched.last_name}
                            success={
                                values?.last_name?.length > 0 &&
                                !errors?.last_name
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.phone.type}
                            label={formField.phone.label}
                            name={formField.phone.name}
                            value={values.phone}
                            placeholder={formField.phone.placeholder}
                            error={errors.phone && touched.phone}
                            success={
                                values?.phone?.length > 0 &&
                                !errors?.phone
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.email_address.type}
                            label={formField.email_address.label}
                            name={formField.email_address.name}
                            value={values.email_address}
                            placeholder={formField.email_address.placeholder}
                            error={errors.email_address && touched.email_address}
                            success={
                                values?.email_address?.length > 0 &&
                                !errors?.email_address
                            }
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Parent Organization
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.parent_organization}
                            error={errors.parent_organization && touched.parent_organization}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.parent_organization.name,
                                    event.parent_organization.value
                                );
                            }}
                        >
                            {formField.parent_organization.options.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.name}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.photo.type}
                            label={formField.photo.label}
                            name={formField.photo.name}
                            value={values.photo}
                            placeholder={formField.photo.placeholder}
                            error={errors.photo && touched.photo}
                            success={values?.photo?.length > 0 && !errors?.photo}
                        />
                    </Grid>
                </Grid>
            </SoftBox>
        </SoftBox>
    )
}

export default SuperAdminField;