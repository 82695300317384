import React from 'react';
import SoftBox from "../../../../../../components/SoftBox";
import SoftTypography from "../../../../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import FormField from "../../../../../../components/FormField";
import Select from "@mui/material/Select";
import SoftInput from "../../../../../../components/SoftInput";
import MenuItem from "@mui/material/MenuItem";
import {FileInput} from "../../../../organization/OrganizationInfo";

const DetailFormField = ({formData, setLogo }) => {
    const { formField, values, errors, touched, setFieldValue } = formData;
    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>Detail Organization</SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.name.type}
                            label={formField.name.label}
                            name={formField.name.name}
                            value={values.name}
                            placeholder={formField.name.placeholder}
                            error={errors.name && touched.name}
                            success={values?.name?.length > 0 && !errors?.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftTypography
                            component='label'
                            variant='caption'
                            fontWeight='bold'
                        >
                            Logo
                        </SoftTypography>
                        <FileInput
                            type='file'
                            accept='image/*'
                            name={"logo"}
                            onChange={(e) => {
                                setLogo(e.target.files[0]);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.person_contact.type}
                            label={formField.person_contact.label}
                            name={formField.person_contact.name}
                            value={values.person_contact}
                            placeholder={formField.person_contact.placeholder}
                            error={
                                errors.person_contact && touched.person_contact
                            }
                            success={
                                values?.person_contact?.length > 0 &&
                                !errors?.person_contact
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.contact_phone.type}
                            label={formField.contact_phone.label}
                            name={formField.contact_phone.name}
                            value={values.contact_phone}
                            placeholder={formField.contact_phone.placeholder}
                            error={
                                errors.contact_phone && touched.contact_phone
                            }
                            success={
                                values?.contact_phone?.length > 0 &&
                                !errors?.contact_phone
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormField
                            type={formField.contact_email.type}
                            label={formField.contact_email.label}
                            name={formField.contact_email.name}
                            value={values.contact_email}
                            placeholder={formField.contact_email.placeholder}
                            error={
                                errors.contact_email && touched.contact_email
                            }
                            success={
                                values?.contact_email?.length > 0 &&
                                !errors?.contact_email
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormField
                            type={formField.website.type}
                            label={formField.website.label}
                            name={formField.website.name}
                            value={values.website}
                            placeholder={formField.website.placeholder}
                            error={errors.website && touched.website}
                            success={
                                values?.website?.length > 0 && !errors?.website
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Packages
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={formField.package_name.value}
                            error={ errors.package_name && touched.package_name}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.package_name.name,
                                    event.target.value
                                );
                                console.log(
                                    event.target.value,
                                    "option values"
                                );
                                setFieldValue(formField.package_name.id, "");
                            }}
                        >
                            {formField.package_name.options?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.name}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>



            </SoftBox>
        </>
    );
};

export default DetailFormField;