/**
 * All of the routes for the Soft UI Dashboard PRO React are added here,
 *   You can add a new route, customize the routes and delete the routes here.
 *
 *   Once you add a new route on this file it will be visible automatically on
 *   the Sidenav.
 *
 *   For adding a new route you can follow the existing routes in the routes array.
 *   1. The `type` key with the `collapse` value is used for a route.
 *   2. The `type` key with the `title` value is used for a title inside the Sidenav.
 *   3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 *   4. The `name` key is used for the name of the route on the Sidenav.
 *   5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 *   6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 *   7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 *   inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 *   8. The `route` key is used to store the route location which is used for the react router.
 *   9. The `href` key is used to store the external links location.
 *   10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 *   10. The `component` key is used to store the component of its route.
 *
 * @format
 */

// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
// import Reports from "layouts/pages/users/reports";
// import NewUser from "layouts/pages/users/new-user";
// import Users from "layouts/pages/users/";
import FarmersList from "layouts/pages/farmers";
import CountryList from "layouts/pages/country";
import AgentsList from "layouts/pages/agents";
import LoansList from "layouts/pages/loans/";
import OngoingLoan from "layouts/pages/loans/ongoingloans";
import CompletedLoans from "layouts/pages/loans/completedloan";
// import LoanTypeList from "layouts/pages/settings";

// Soft UI Dashboard PRO React icons
import Shop from "eco/Icons/Shop";
import Office from "eco/Icons/Office";
import CustomerSupport from "eco/Icons/CustomerSupport";
import Organization from "layouts/pages/organization";
import CreateNew from "layouts/pages/organization/CreateNew";
import Sponsor from "layouts/pages/sponsor";
import CreateProject from "layouts/pages/projects/CreateProject";
import General from "layouts/pages/projects/general";
import Forest from "layouts/pages/forests";
import AddFarmer from "layouts/pages/farmers/addNewFarmer/AddFarmer";
import SupportList from "layouts/pages/supports";
import Delayed from "layouts/pages/supports/delayed";
import OnPauseLoan from "layouts/pages/loans/on-pause";
import ApplicationSubmittedLoans from "layouts/pages/loans/application-submitted";
import UnderReviewLoans from "layouts/pages/loans/under-review";
import ApprovedLoans from "layouts/pages/loans/approved";
import DeclinedLoans from "layouts/pages/loans/declined";
import CompletedTasks from "layouts/pages/supports/completed";
import InProgress from "layouts/pages/supports/in-progress";
import PendingTask from "layouts/pages/supports/pending";
import DeclinedTasks from "layouts/pages/supports/declined";
import Payment from "layouts/pages/payments";
import TreeSpecies from "layouts/pages/tree-species";
import ForestRelease from "layouts/pages/forests/forest-release";
import Users from "layouts/pages/users";
import NewUser from "layouts/pages/users/new-user";
import Session from "layouts/pages/sessions";
import OverviewPage from "./layouts/pages/overview";
import AdministrationPage from "./layouts/pages/administration"

const routes = [
    {
        type: "collapse",
        name: "Dashboards",
        key: "",
        route: "/",
        component: <Default />,
        icon: <Shop size='12px' />,
        noCollapse: true,
    },
    { type: "title", title: "Actions", key: "actions" },
    {
        type: "collapse",
        name: "Farmers",
        key: "farmers",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "All Farmers",
                key: "farmers",
                route: "/farmers/list",
                component: <FarmersList />,
            },
            {
                name: "Add Farmer",
                key: "farmers",
                route: "/farmers/add-new",
                component: <AddFarmer />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Forest",
        key: "forest",
        icon: <Office size='12px' />,
        route: "/forest/list",
        component: <Forest />,
        collapse: [
            {
                name: "Forest Release",
                key: "forest",
                route: "/forest/release/list",
                component: <ForestRelease />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Agents",
        key: "agents",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "All agents",
                key: "agents",
                route: "/agents/list",
                component: <AgentsList />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Organization",
        key: "organization",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "All Organization",
                key: "organization",
                route: "/organization/list",
                component: <Organization />,
            },
            {
                name: "Create new",
                key: "organization",
                route: "/organization/create-new",
                component: <CreateNew />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Users",
        key: "users",
        icon: <CustomerSupport size='12px' />,
        collapse: [
            {
                name: "All users",
                key: "users",
                route: "/users",
                component: <Users />,
            },
            {
                name: "Add user",
                key: "users",
                route: "/users/add-new",
                component: <NewUser />,
            },
        ],
    },

    {
        type: "collapse",
        name: "Countries",
        key: "countries",
        icon: <Office size='12px' />,
        route: "/countries",
        component: <CountryList />,
        noCollapse: true,
    },
    {
        type: "collapse",
        name: "Loans",
        key: "loans",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "All loans",
                key: "loans",
                route: "/loans/all",
                component: <LoansList />,
            },
            {
                name: "Application submitted",
                key: "loans",
                route: "/loans/application",
                component: <ApplicationSubmittedLoans />,
            },
            {
                name: "Under review loans",
                key: "loans",
                route: "/loans/underreview",
                component: <UnderReviewLoans />,
            },
            {
                name: "Approved loans",
                key: "loans",
                route: "/loans/approved",
                component: <ApprovedLoans />,
            },
            {
                name: "On pause loans",
                key: "loans",
                route: "/loans/onpause",
                component: <OnPauseLoan />,
            },
            {
                name: "Ongoing loans",
                key: "loans",
                route: "/loans/ongoing",
                component: <OngoingLoan />,
            },
            {
                name: "Completed loans",
                key: "loans",
                route: "/loans/completed",
                component: <CompletedLoans />,
            },
            {
                name: "Declined loans",
                key: "loans",
                route: "/loans/declined",
                component: <DeclinedLoans />,
            },
            // {
            //     name: "Users",
            //     key: "users",
            //     collapse: [
            //         {
            //             name: "Reports",
            //             key: "reports",
            //             route: "/pages/users/reports",
            //             component: <Reports />,
            //         },
            //         {
            //             name: "New User",
            //             key: "new-user",
            //             route: "/pages/users/new-user",
            //             component: <NewUser />,
            //         },
            //     ],
            // },
        ],
    },
    // {
    //     type: "collapse",
    //     name: "Settings",
    //     key: "settings",
    //     icon: <CustomerSupport size='12px' />,
    //     collapse: [
    //         {
    //             name: "Loan types",
    //             key: "settings",
    //             route: "/settins/loan/loan-types",
    //             component: <LoanTypeList />,
    //         },
    //     ],
    // },
    {
        type: "collapse",
        name: "Sponsor",
        key: "sponsor",
        icon: <CustomerSupport size='12px' />,
        collapse: [
            {
                name: "Sponsor",
                key: "sponsor",
                route: "/sponsor/all",
                component: <Sponsor />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Project",
        key: "project",
        icon: <CustomerSupport size='12px' />,
        collapse: [
            {
                name: "All Project",
                key: "project",
                route: "/project/all",
                component: <General />,
            },
            {
                name: "Create a project",
                key: "project",
                route: "/project/new",
                component: <CreateProject />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Payment",
        key: "payment",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "All Payment",
                key: "payment",
                route: "/payement/all",
                component: <Payment />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Tree Species",
        key: "tree-species",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "All Tree species",
                key: "tree-species",
                route: "/tree-species/all",
                component: <TreeSpecies />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Supports",
        key: "support",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "All",
                key: "support",
                route: "/support/all",
                component: <SupportList />,
            },
            {
                name: "Completed",
                key: "support",
                route: "/support/status/completed",
                component: <CompletedTasks />,
            },
            {
                name: "In progress",
                key: "support",
                route: "/support/status/progress",
                component: <InProgress />,
            },
            {
                name: "Pending",
                key: "support",
                route: "/support/status/pending",
                component: <PendingTask />,
            },
            {
                name: "Declined",
                key: "support",
                route: "/support/status/declined",
                component: <DeclinedTasks />,
            },

            {
                name: "More than 1 day without response",
                key: "support",
                route: "/support/delayed",
                component: <Delayed />,
            },
        ],
    },
    {
        type: "collapse",
        name: "USSD Sessions",
        key: "sessions",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "View sessios",
                key: "sessions",
                route: "/sessions/count",
                component: <Session />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Overview",
        key: "overview",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "Admin overview",
                key: "overview",
                route: "/overview/administration",
                component: <OverviewPage />,
            },
        ],
    },
    {
        type: "collapse",
        name: "Packages",
        key: "packages",
        icon: <Office size='12px' />,
        collapse: [
            {
                name: "Packages Overview",
                key: "packages",
                route: "/packages/statistics",
                component: <AdministrationPage />,
            },
        ],
    },
];

export default routes;
