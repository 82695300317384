/** @format */

import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "features/api/apiSliceV1";
import agentSlice from "features/slices/agentSlice";
import dashboardSlice from "features/slices/dashboardSlice";
import farmerSlice from "features/slices/farmerSlice";
import forestSlice from "features/slices/forest";
import userSlice from "features/slices/userSlice";
import logger from "redux-logger";
import countrySlice from "features/slices/countrySlice";
import loanSlice from "features/slices/loanSlice";
import loanTypeSlice from "features/slices/loanTypeSlice";
import taskSlice from "features/slices/taskSlice";
import organizationTypeSlice from "features/slices/organizationSlice";
import sponsorSlice from "features/slices/sponsorSlice";
import provinceTypeSlice from "features/slices/provinceSlice";
import villageTypeSlice from "features/slices/villageSlice";
import cellTypeSlice from "features/slices/cellSlice";
import districtTypeSlice from "features/slices/districtSlice";
import sectorTypeSlice from "features/slices/sectorSlice";
import projectSlice from "features/slices/projectSlice";
import supportSlice from "features/slices/supportSlice";
import paymentSlice from "features/slices/paymentSlice";
import treeSpecieSlice from "features/slices/treeSpeciesSlice";
import releaseForestSlice from "features/slices/forestReleaseSlice";
import sessionSlice from "features/slices/sessions";
import packageSlice from "../features/slices/packageSlice";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        user: userSlice.reducer,
        dashboard: dashboardSlice.reducer,
        farmers: farmerSlice.reducer,
        agent: agentSlice.reducer,
        forest: forestSlice.reducer,
        country: countrySlice.reducer,
        loan: loanSlice.reducer,
        loanType: loanTypeSlice.reducer,
        task: taskSlice.reducer,
        sponsor: sponsorSlice.reducer,
        province: provinceTypeSlice.reducer,
        organization: organizationTypeSlice.reducer,
        village: villageTypeSlice.reducer,
        cell: cellTypeSlice.reducer,
        district: districtTypeSlice.reducer,
        sector: sectorTypeSlice.reducer,
        project: projectSlice.reducer,
        support: supportSlice.reducer,
        payment: paymentSlice.reducer,
        treeSpecies: treeSpecieSlice.reducer,
        forestRelease: releaseForestSlice.reducer,
        session: sessionSlice.reducer,
        package: packageSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiSlice.middleware).concat(logger),
    devTools: true,
});
