import React from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa6'

const Container = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
`

const Title = styled.h4`
    color: #343a40;
    font-size: 1.3rem;
    font-width: 300;
`
const TitleWrapper = styled.div`
    padding: 0.6rem 2rem;
    width: 350px;
    background-color: #FFF;
    border-radius: 30px 30px 0 0;
    margin-bottom: -5px;
    -webkit-box-shadow: -7px 6px 2px -1px rgba(163,161,163,1);
    -moz-box-shadow: -7px 6px 2px -1px rgba(163,161,163,1);
    box-shadow: -7px 6px 2px -1px rgba(163,161,163,1);
`

const Grid = styled.div`
  display: grid;
    background-color: #FFF;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
    //border: 1px solid var(--primary-color);
    padding: 1rem 2rem;
    border-radius: 0 30px 30px 30px;
    -webkit-box-shadow: -7px 6px 2px -1px rgba(163,161,163,1);
    -moz-box-shadow: -7px 6px 2px -1px rgba(163,161,163,1);
    box-shadow: -7px 6px 2px -1px rgba(163,161,163,1);
`

const CheckboxItem = styled.div`
    display: flex;
    align-items: center;
`

const StyledCheckbox = styled.div`
      margin-right: 10px;
      width: 20px;
      height: 20px;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    background-color: ${props => props.checked ? "var(--primary-color)" : "white"};;
`

const Label = styled.label`
  color: #495057;
  font-size: 14px;
`

const PackageComponent = ({ data, title, handleCheckboxChange }) => {
    return (
        <Container>
            <TitleWrapper>
                <Title>{title || 'Green Agent'}</Title>
            </TitleWrapper>
            <Grid>
                {Object.entries(data).map(([key, value]) => (
                    <CheckboxItem key={key}>
                        <StyledCheckbox checked={value} onClick={() => handleCheckboxChange(key)}>
                            {value && <FaCheck color={"white"} size={15}/>}
                        </StyledCheckbox>
                        <Label htmlFor={key}>{key.split('_').join(' ')}</Label>
                    </CheckboxItem>
                ))}
            </Grid>
        </Container>
    )
}

export default PackageComponent