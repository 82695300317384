import React from 'react';
import SoftBox from "../../../../../../components/SoftBox";
import SoftTypography from "../../../../../../components/SoftTypography";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import SoftInput from "../../../../../../components/SoftInput";
import MenuItem from "@mui/material/MenuItem";

const CurrencyField = ({ formData }) => {
    const { formField, values, errors, touched, setFieldValue } = formData;
    return (
        <>
            <SoftBox p={3}>
                <SoftTypography variant='h5'>Setup Currency</SoftTypography>
            </SoftBox>
            <SoftBox component='form' pb={3} px={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Currency
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.currency}
                            error={errors.currency && touched.currency}
                            onChange={(event) => {
                                setFieldValue(
                                    formField.currency.name,
                                    event.target.value
                                );
                                console.log(
                                    event.target.value,
                                    "option values"
                                );
                                setFieldValue(formField.currency.id, "");
                            }}
                        >
                            {formField.currency.options?.map((roleOption) => (
                                <MenuItem
                                    key={roleOption.id}
                                    value={roleOption.name}
                                >
                                    {roleOption.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display='inline-block'
                        >
                            <SoftTypography
                                component='label'
                                variant='caption'
                                fontWeight='bold'
                                textTransform='capitalize'
                            >
                                Currency Symbol
                            </SoftTypography>
                        </SoftBox>
                        <Select
                            input={<SoftInput />}
                            value={values.currency_symbol}
                            error={
                                errors.currency_symbol &&
                                touched.currency_symbol
                            }
                            onChange={(event) => {
                                setFieldValue(
                                    formField.currency_symbol.name,
                                    event.target.value
                                );
                                console.log(
                                    event.target.value,
                                    "option values"
                                );
                                setFieldValue(formField.currency_symbol.id, "");
                            }}
                        >
                            {formField.currency_symbol?.options?.map(
                                (roleOption) => (
                                    <MenuItem
                                        key={roleOption.id}
                                        value={roleOption.name}
                                    >
                                        {roleOption.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </Grid>
                </Grid>

            </SoftBox>
        </>
    );
};

export default CurrencyField;