/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "features/api/apiSliceV1";

export const organizationApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllOrganizations: builder.query({
            query: () => "organizations",
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
            providersTags: ["organizationType"],
        }),
        getOrganizationById: builder.query({
            query: (id) => `organizations/${id}`,
            refechOnMount: "always",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getOrganizationsStat: builder.query({
            query: () => "organizations/statistics",
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        getOrganizationStatById: builder.query({
            query: (id) => `organizations/${id}/statistics`,
            refetchOnReconnect: true,
            transformReconnect: (response, meta, arg) => response.data,
        }),
        createOrganization: builder.mutation({
            query: (values) => {
                return {
                    url: "organizations",
                    method: "POST",
                    body: values,
                };
            },
            invalidatesTags: ["organizationType"],
        }),

        updateOrganization: builder.mutation({
            query: ({ values, id }) => {
                return {
                    url: `organizations/${id}`,
                    method: "PATCH",
                    body: values,
                };
            },
            invalidatesTags: ["organizationType"],
        }),
        updateOrganizationLogo: builder.mutation({
            query: ({ values, id }) => {
                return {
                    url: `organizations/${id}/logo`,
                    method: "PATCH",
                    body: values,
                };
            },
            invalidatesTags: ["organizationType"],
        }),
    }),
});

export const {
    useGetAllOrganizationsQuery,
    useCreateOrganizationMutation,
    useGetOrganizationByIdQuery,
    useUpdateOrganizationMutation,
    useGetOrganizationsStatQuery,
    useGetOrganizationStatByIdQuery,
    useUpdateOrganizationLogoMutation,
} = organizationApi;

const organizationTypeSlice = createSlice({
    name: "organizationType",
    initialState: {
        organizations: [],
        parent_organizations: [],
        stepForm: 0,
    },
    reducers: {
        setStepForm: (state, action) => {
            state.stepForm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            organizationApi.endpoints.getAllOrganizations.matchFulfilled,
            (state, action) => {
                state.organizations = action.payload;
            }
        );
        builder.addMatcher(
            organizationApi.endpoints.createOrganization.matchFulfilled,
            (state, action) => {
                state.organizations = action.payload;
            }
        );
        builder.addMatcher(
            organizationApi.endpoints.getOrganizationsStat.matchFulfilled,
            (state, action) => {
                state.organizations = action.payload;
            }
        );
        builder.addMatcher(
            organizationApi.endpoints.getOrganizationStatById.matchFulfilled,
            (state, action) => {
                state.organizations = action.payload;
            }
        );
        builder.addMatcher(
            organizationApi.endpoints.getOrganizationById.matchFulfilled,
            (state, action) => {
                state.organizations = action.payload;
            }
        );
        builder.addMatcher(
            organizationApi.endpoints.updateOrganization.matchFulfilled,
            (state, action) => {
                state.organizations = action.payload;
            }
        );
    },
});

export const { setStepForm } = organizationTypeSlice.actions;

export default organizationTypeSlice;
