/** @format */

export const parentOrgFormStep1 = {
    formId: "new-parent-organization-form-1",
    formField: {
        name: {
            name: "name",
            label: "Name",
            type: "text",
            placeholder: "e.g. ISARO ECONext",
            errorMsg: "Company name is required.",
        },
        contact_email: {
            name: "contact_email",
            label: "Email Address",
            type: "email",
            placeholder: "e.g. isaro.eco@gmail.com",
            errorMsg: "Email address is required.",
        },
        person_contact: {
            name: "person_contact",
            label: "Person contact",
            type: "text",
            placeholder: "e.g. John Doe",
            errorMsg: "Person contact name is required.",
        },
        logo: {
            name: "logo",
            label: "Company logo",
            type: "file",
            placeholder: "e.g. John Doe",
            errorMsg: "Company logo is required.",
        },
        contact_phone: {
            name: "contact_phone",
            label: "Phone",
            type: "string",
            placeholder: "eg. 0788xxxxxx",
            errorMsg: "person phone is required.",
        },
        website: {
            name: "website",
            label: "Website",
            type: "text",
            placeholder: "e.g. https://examplte.com",
            errorMsg: "Website link is required.",
        },
        package_name: {
            name: "package",
            label: "Package",
            options: [
                { id: "1", name: "Basic" },
                { id: "2", name: "Colden" },
            ],
            errorMsg: "Packages is required.",
        },
    },
};


export const parentOrganizationFormStep2 = {
   formId: 'create-new-parent-organization-2',
    formField: {
        country_id: {
            name: "country_id",
            label: "Country",
            type: "select",
            options: [
                { id: "1", name: "Rwanda" },
                { id: "2", name: "Burundi" },
                { id: "3", name: "Kenya" },
                { id: "4", name: "Tanzania" },
            ],
            errorMsg: "Country is required.",
        },
        province: {
            name: "province",
            label: "Province",
            type: "select",
            options: [
                { id: "1", name: "Kigali" },
                { id: "2", name: "East" },
                { id: "3", name: "West" },
            ],
            errorMsg: "This field is required.",
            invalidMsg: "Level can't be more than 5 and less than 1",
        },
        district: {
            name: "district",
            label: "District",
            type: "select",
            options: [
                { id: "1", name: "District 1" },
                { id: "2", name: "District 2" },
                { id: "3", name: "District 3" },
            ],
            errorMsg: "This field is required.",
        },
        sector: {
            name: "sector",
            label: "Sector",
            type: "select",
            options: [
                { id: "1", name: "Sector 1" },
                { id: "2", name: "Sector 2" },
                { id: "1", name: "Sector 3" },
            ],
            errorMsg: "This field is required.",
        },
        cell: {
            name: "cell",
            label: "Cell",
            type: "select",
            options: [
                { id: "1", name: "Cell 1" },
                { id: "2", name: "Cell 2" },
                { id: "3", name: "Cell 3" },
            ],
            errorMsg: "This field is required.",
        },
        village: {
            name: "village",
            label: "Vilage",
            type: "select",
            options: [
                { id: "1", name: "Village 1" },
                { id: "2", name: "Village 2" },
                { id: "3", name: "Village 3" },
            ],
            errorMsg: "This field is required.",
        },
    }
}


export const parentOrganizationFormStep3 = {
    formId: 'create-new-parent-organization-3',
    formField: {
        currency: {
            name: "currency",
            label: "Currency",
            options: [
                { id: "USD", name: "American Dollar" },
                { id: "RWF", name: "Rwandan Franc" },
                { id: "GBP", name: "Great British Pound" },
            ],
            errorMsg: "Currency is required.",
        },
        currency_symbol: {
            name: "currency_symbol",
            label: "Currency symbol",
            options: [
                { id: "USD", name: "$" },
                { id: "RWF", name: "RWF" },
                { id: "GBP", name: "£" },
            ],
            errorMsg: "Currency symbol is required.",
        },

    }
}

export const parentOrganizationFormStep4 = {
    formId: 'create-new-parent-organization-4',
    formField: {
        currency: {
            name: "currency",
            label: "Currency",
            options: [
                { id: "USD", name: "American Dollar" },
                { id: "RWF", name: "Rwandan Franc" },
                { id: "GBP", name: "Great British Pound" },
            ],
            errorMsg: "Currency is required.",
        },
        currency_symbol: {
            name: "currency_symbol",
            label: "Currency symbol",
            options: [
                { id: "USD", name: "$" },
                { id: "RWF", name: "RWF" },
                { id: "GBP", name: "£" },
            ],
            errorMsg: "Currency symbol is required.",
        },

    }
}

