/** @format */

import { Card, Grid } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "eco/Navbars/DashboardNavbar";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SubscriptionChart from "./ChartUser";
import LoadingCardSkeleton from "components/skeleton/LoadingSkeleton";
import OrgCard from "eco/Cards/OrgCard";
import DataTable from "eco/Tables/DataTable";
import { dummyDataPackage } from "../../../../constants/data";
import AdminProfileModal from "../ProfileModal";
import administrationColumn from "../data-table/column.admin";

function PackageOverview() {
    const packageName = useParams();
    const title = packageName.package.toUpperCase();
    const { values } = breakpoints;
    const [isLoading] = useState(false);
    const filterPerPackage = dummyDataPackage.filter(
        (item) => item.package.toLowerCase() == packageName.package
    );
    console.log("filtered data ====>", filterPerPackage);
    const [modalView, setModalView] = useState(false);
    const [_, setAdminId] = useState(null);
    const profileData = {
        name: "GAARA Fund",
        status: "Active",
        parentOrganization: "GAARA Fund",
        organization: "GAARA Fund",
        email: "info@gaara.rw",
        phone: "+250788207071",
        photoUrl: "", // Add photo URL if available
    };

    const handleOpen = (id) => {
        setAdminId(id);
        setModalView(true);
    };
    const handleClose = () => setModalView(false);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    {title} PACKAGE
                </SoftTypography>
            </SoftBox>
            <SoftBox pt={3}>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    Package Statistics
                </SoftTypography>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={9}>
                            <SubscriptionChart
                                data={[
                                    10, 0, 20, 32, 32, 0, 54, 69, 67, 32, 11,
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} xl={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoading}
                                        height='133px'
                                    >
                                        <OrgCard
                                            count={50}
                                            suffix=''
                                            title='Subscribes'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoading}
                                        height='133px'
                                    >
                                        <OrgCard
                                            count={10}
                                            suffix=''
                                            title='Countries'
                                            href={`50`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <LoadingCardSkeleton
                                        isLoading={isLoading}
                                        height='133px'
                                    >
                                        <OrgCard
                                            count={28009000}
                                            suffix=''
                                            title='Overdue Payment'
                                            href={`#`}
                                        />
                                    </LoadingCardSkeleton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} xl={12} mt={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <LoadingCardSkeleton
                                    isLoading={isLoading}
                                    height='133px'
                                >
                                    <OrgCard
                                        count={28009000}
                                        suffix='Rwf'
                                        title='Montly Subscription'
                                        href={`#`}
                                    />
                                </LoadingCardSkeleton>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <LoadingCardSkeleton
                                    isLoading={isLoading}
                                    height='133px'
                                >
                                    <OrgCard
                                        count={28009000}
                                        suffix='Rwf'
                                        title='Consumable Services'
                                        href={``}
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LoadingCardSkeleton
                                    isLoading={isLoading}
                                    height='133px'
                                >
                                    <OrgCard
                                        count={28009000}
                                        suffix='Rwf'
                                        title='Tree Update Cost'
                                        href={`#`}
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <LoadingCardSkeleton
                                    isLoading={isLoading}
                                    height='133px'
                                >
                                    <OrgCard
                                        count={28009000}
                                        suffix='Rwf'
                                        title='Total Upfront Fee'
                                        href={`#`}
                                    />
                                </LoadingCardSkeleton>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftTypography
                    variant={window.innerWidth < values.sm ? "h3" : "h2"}
                    textTransform='capitalize'
                    fontWeight='bold'
                    gutterBottom
                >
                    List of {packageName.package} packages
                </SoftTypography>
                <SoftBox>
                    <LoadingCardSkeleton isLoading={isLoading} height='333px'>
                        <Card>
                            <DataTable
                                table={administrationColumn(handleOpen)} // Your columns configuration
                                entriesPerPage={{
                                    defaultValue: 5,
                                    entries: [5, 10, 15],
                                }} // Pagination configuration
                                canSearch // Enable search
                                theData={filterPerPackage} // Your data
                                showTotalEntries // Shows total entries in the table
                                pagination={{
                                    variant: "gradient",
                                    color: "info",
                                }} // Pagination design
                            />
                        </Card>
                    </LoadingCardSkeleton>
                </SoftBox>
            </SoftBox>
            {modalView && (
                <AdminProfileModal
                    open={modalView}
                    onClose={handleClose}
                    profileData={profileData}
                />
            )}
        </DashboardLayout>
    );
}

export default PackageOverview;
