import React from 'react';
import DashboardLayout from "../../../../eco/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../eco/Navbars/DashboardNavbar";
import SoftTypography from "../../../../components/SoftTypography";
import breakpoints from "../../../../assets/theme/base/breakpoints";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";
import SoftButton from "../../../../components/SoftButton";
import CreateName from "./CreateName";
import PackageComponent from "../Package";
import { BsEmojiSmile } from "react-icons/bs";

function CreatePage() {
    const { values } = breakpoints;
    const [step, setStep] = React.useState(0);
    const [namePackage, setNamePackage] = React.useState("");
    const [packageData, setPackageData] = React.useState({
        name: null,
        isSaved: false,
    });
    const handleChange = () => {
        setPackageData((prev)=> ({
            ...prev,
            name: namePackage
        }))
    }
    const [greenBoxState, setGreenBoxState] = React.useState({
        forests_need_Update: true,
        total_forest: false,
        total_leased_forest: true,
        total_land_measured: true,
        total_land_restored: true,
        percent_of_total_trees_inside_of_forest: true,
        total_trees_planted: true,
        total_unserved_tress: false,
        total_land_restored_2: false,
        percent_of_total_trees_inside_of_forest_2: true,
        total_trees_planted_2: true,
        total_unserved_tress_2: true,
    });

    const [forestBoxState, setForestBoxState] = React.useState({
        forests_need_Update: true,
        total_forest: false,
        total_leased_forest: true,
        total_land_measured: true,
        total_land_restored: true,
        percent_of_total_trees_inside_of_forest: true,
        total_trees_planted: true,
        total_unserved_tress: false,
        total_land_restored_2: false,
        percent_of_total_trees_inside_of_forest_2: true,
        total_trees_planted_2: true,
        total_unserved_tress_2: true,
    });

    const handleForestCheckboxChange = (key) => {
        setForestBoxState((prevState) => ({
            ...prevState,
            [key]: !prevState[key], // Toggle the value of the clicked checkbox
        }));
    };

    const handleGreenCheckboxChange = (key) => {
        setGreenBoxState((prevState) => ({
            ...prevState,
            [key]: !prevState[key], // Toggle the value of the clicked checkbox
        }));
    };
    const renderStep = () => {
        if(step === 0) {
            return <CreateName name={packageData.name} setNamePage={setNamePackage} />
        }

        if(step === 1) {
            return <PackagesWrapper>
                <PackageComponent data={greenBoxState} title={"Green Agent"} handleCheckboxChange={handleGreenCheckboxChange} />
                <PackageComponent data={forestBoxState} title={"Forest"} handleCheckboxChange={handleForestCheckboxChange} />
            </PackagesWrapper>
        }

        if(step === 2) {
            return <PackagesSaveWrapper>
                <PackageSaveContent>
                    <BsEmojiSmile size={40}/>
                    <PackageSaveTitle>Package is saved</PackageSaveTitle>
                </PackageSaveContent>
            </PackagesSaveWrapper>
        }
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <SoftTypography variant={window.innerWidth < values.sm ? "h3" : "h2"} textTransform='capitalize' fontWeight='bold' gutterBottom>
                Create package
            </SoftTypography>
            <Framer>
                <MulterStepContainer>
                    <LineThough/>
                    <StepIndicator>
                        {packageData.name && (
                            <FaCheck size={18}/>
                        )}
                    </StepIndicator>
                    <StepIndicator>
                        {step > 0 && (
                            <FaCheck size={18}/>
                        )}
                    </StepIndicator>
                    <StepIndicator>
                        {step > 1 &&  (
                        <FaCheck size={18}/>
                        )}
                    </StepIndicator>
                </MulterStepContainer>
                <FormFrame>
                    <FormFrameHeader>
                        <Label> {packageData.name && `Setup ${packageData.name}`} Package</Label>
                    </FormFrameHeader>
                    {renderStep()}
                    <FormSteps>
                        {step !== 2 && <SoftButton
                            variant='gradient'
                            color='dark'
                            handleClick={()=> {
                                if(namePackage.length > 2){
                                    handleChange();
                                    if(step < 3) {
                                        setStep(step + 1)
                                    }
                                }
                            }}
                        >
                            Save and continue
                        </SoftButton>}

                    </FormSteps>
                </FormFrame>
            </Framer>

        </DashboardLayout>
    );
}

const Framer = styled.div`
    
`

const MulterStepContainer = styled.div`
    position: relative;
    display: flex;
    max-width: 500px;
    justify-content: space-between;
    margin: 0 auto;
`

const StepIndicator = styled.div`
    width: 30px;
    height: 30px;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
`
const LineThough = styled.div`
    width: 100%;
    position: absolute;
    top: 15px;
    height: 2px;
    background-color: #161f29;
`

const FormFrameHeader = styled.div`
    border-radius: 12px 12px 0 0;
    background-color: var(--primary-color);
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 45px;
`

const FormFrame = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 12px auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
    border-radius: 12px 12px 0 0;
    border: 1px solid var(--primary-color);
    min-height: 50svh;
`

const FormSteps = styled.div`
    padding: 8px 16px;
    display: flex;
    justify-content: flex-end;
`

const Label = styled.h3`
    color: #FFF;
`

const PackagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem
`

const PackagesSaveWrapper = styled.div`
    height: 30svh;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    -webkit-box-shadow: -1px 0px 2px 2px rgba(163,161,163,1);
    -moz-box-shadow: -1px 0px 2px 2px rgba(163,161,163,1);
    box-shadow: -1px 0px 2px 2px rgba(163,161,163,1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const PackageSaveContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
`
const PackageSaveTitle = styled.h3`
    font-size: 2rem;
    text-align: center;
`

export default CreatePage;